import styled from 'styled-components';

export const InfoSec = styled.div`
    color: #fff;
    /* padding: 100px 0; */
    width: 100vw;
    background-repeat: no-repeat;
    background:linear-gradient(7deg, #F57F20, #000);
    /* background-position: A02D2B a9b3c1*/
`;

export const InfoRow = styled.div`
    display: flex;
    /* margin: 0 -15px -15px -15px; */
    flex-wrap: wrap;
    align-items: center;
    flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
    /* margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px; */
    padding: 10px;
    margin: 10px;
    flex: 1;
    /* max-width: 50%; */
    width: 50vw;
    /* flex-basis: 50%; */

    @media screen and (max-width: 768px) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const TextWrapper = styled.div`
    /* max-width: auto; */
    /* padding-top: 0; */
    padding-bottom: 60px;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding-bottom: 65px;
    }
`;

export const TopLine = styled.div`
    color: ${({ lightTopLine }) => (lightTopLine ? '#a9b3c1' : '#4B59F7')};
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 1.4px;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 3rem;
    /* line-height: 1.1; */
    text-align: center;
    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#1c2237')};
`;

export const Subtitle = styled.p`
    /* max-width: 440px; */
    margin-bottom: 35px;
    font-size: 1.3rem;
    /* line-height: 24px; */
    color: ${({lightTextDesc}) => (lightTextDesc ? '#a9b3c1' : '#1c2237')};
    text-align: center;
    margin: 5vh 7vw;
`;

export const ImgWrapper = styled.div`
    /* max-width: 555px; */
    display: flex;
    justify-content: ${({ start }) => (start ? 'flex-start': 'flex-end')};
`;

export const Img = styled.img`
    /* padding: 10px; */
    /* border: 0; */
    display: inline-block;
    height: 40vh;
    @media screen and (max-width: 768px) {
        height: 30vh;
    }

    @media screen and (max-width: 375px) {
        height: 20vh;
    }


`;