import styled from "styled-components";


export const Container = styled.div`
    width: 100vw;
    height: auto;
    justify-content: center;
    vertical-align: center;
    /* background: #000; */
    background: linear-gradient(90deg, #009E5F, #F57F20);
`;

export const Headline = styled.h1`
    color: #fff;
    text-align: center;
    padding: 20px 0;
`;


export const MainImg = styled.img`
    width: auto;
    height: 50vh;
    `;

export const CenterBioPic = styled.div`
    margin: 0 auto;
    text-align: center;

`;

export const MainText = styled.p`
    color: #fff;
    text-align: center;
    width: 60vw;
    height: auto;
    margin: 0 auto ;
    padding: 20px 0
`;