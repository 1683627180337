import React from 'react'
import { Container, CircleImg, MainRowBS, ImgWrap, Header1, SectionText } from './BasicSubSec.styles';

export const BasicSubSec = ({
  topImg,
  alt,
  headline,
  description
}) => {
  return (
    <>

    <Container>
      <MainRowBS>
        <Header1>{headline}</Header1>
        <ImgWrap>
      <CircleImg src={topImg} alt={alt}/>
      </ImgWrap>
      <SectionText>{description}</SectionText>
      </MainRowBS>

    </Container>

    </>
  )
}
