import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/MainPages/Home';
import About from './components/MainPages/About';
// import Services from './components/MainPages/Services';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { ContactSection } from './components/Forms/ContactSection';

function App() {
  return (
    <>
    <Router>
        <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<ContactSection/>} />
        <Route path='/' element={<Home/>} />
      </Routes>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
