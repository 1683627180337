export const data4WS = {
    // mainImg: require('../../assests/images/big_red_right.jpg').default,
    greeting: "Ivoire Truck is here for you",
    simpleText1: "this is where I will say something clever about Ivoire Truck LLC",
}

export const data4BSs1 = {
    topImg: require('../../assests/images/left_side_white.jpg'),
    alt: "left side white truck",
    headline: "Our Mission",
    description: "Ivoire Truck LLC is here to take care of your transportation needs, providing you with all the trucking service you need to keep your business moving as expected!"
}

export const data4HomeSec1 = {
    sectionOneImg: require('../../assests/images/big_white_2_right.jpg'),
    primary: true,
    imgStart:'',
    lightTopLine: true,
    lightTextDesc: true,
    buttonLabel: 'Let\'s Connect',
    description: 'We provide top quality trucking services with you in mind. We Care for our customers in the best way possible.',
    // description: 'At Lion Heart Driving Academy, we provide students with the skills to get a Class A or Class B CDL',
    // headline: 'Discover the Lion Heart difference!',
    // description: 'Join our team as we funtion as an essential component to move the economy forward!',
    headline: 'WE CARE!',
    lightText: true,
    topLine: 'CDL Training Courses',
    img: '', 
    alt: 'Image', 
    start: ''
}

export const data4HomeSec2 = {
    sectionOneImg: require('../../assests/images/white_truck_side.jpg'),
    sectionTwoImg: require('../../assests/images/red_door_open.jpg'),
    primary: true,
    imgStart:'',
    lightTopLine: true,
    lightTextDesc: true,
    description: 'Quality Trucking Services in the heart of Oklahoma City',
    headline: 'OKC Locals',
    lightText: true,
    topLine: 'CDL Training Courses',
    img: '', 
    alt: 'Image', 
    start: ''
}

export const data4BioSec = {
    headline: "Meet Our CEO",
    bioImg: require('../../assests/images/my_guy.jpg'),
    alt: "left side white truck",
    description: "Meet our CEO Moussa Konate. Leader, Learner, and Business Man. Born August 8th, 1995 in the beautiful Ivory Coast of West Africa. Moussa has a Bachelor science in chemistry with minor in mathematics from Langston university, with plans on entering medical school to further his education. He is excellent when it comes to execution and quality. With plans of expanding Ivoire Truck, Moussa with a humble heart keeps in mind his impact in the communities his company serves."
}