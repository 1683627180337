import React from 'react';
import { WelcomSubsec } from '../SubSec/WelcomeSubsec';
import { data4WS, data4BSs1, data4HomeSec1, data4HomeSec2 } from '../SubSec/Data';
import { BasicSubSec } from '../SubSec/BasicSubSec';
// import { InfoSectionWith2Img } from '../InfoSection/InfoSectionWith2Img';
import { InfoSection } from '../InfoSection/InfoSection';
import { InfoSection2 } from '../InfoSection/InfoSection2';

const Home = () => {
    return (
        <>
        <WelcomSubsec {...data4WS}/>
        <BasicSubSec {...data4BSs1}/>
        {/* <InfoSectionWith2Img {...data4HomeSec2}/> */}
        <InfoSection2 {...data4HomeSec2}/>
        <InfoSection {...data4HomeSec1}/>
        </>
    )
}

export default Home
