import styled from "styled-components";
import mainImg from '../../assests/images/big_red_right.jpg';

export const Container = styled.div`
    width: 100vw;
    height: 40vh;
    background: #009E5F;
    `;

export const MainRow = styled.div`
    padding-top: 5vh;
    background-image: url(${mainImg});
    width: 100vw;
    height: 25vh;
    background-position: left right;
    background-repeat: no-repeat;
    background-size: 35vh;
    background-color: #F57F20;
  /* background-color: linear-gradient(90deg, #009E5F, #F57F20); */
    opacity: 0.80;
`;

export const MiddleSec = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: #000;
    opacity: 0.7;
    width: 60vw;
    height: 25vh;
    padding: 10px 20px;
`;

export const Greeting = styled.h1`
    font-size: 450%;
    color: #fff;
    @media screen and (max-width: 1024px) {
        font-size: 350%;
    }

    @media screen and (max-width: 768px) {
        font-size: 300%;
    }

    @media screen and (max-width: 425px) {
        font-size: 217.7%;
    }
`;