import styled from "styled-components";

export const Container = styled.div`
    color: #000;
    height: auto;
    width: 100vw;
    
`;

export const MainRowBS = styled.div`
    width: 100vw;
    height: auto;
    background: #000;
    align-items: center;
    padding: 40px 0px;

`;

export const ImgWrap = styled.div`
    margin: 0 auto;
    width: auto;
    height: auto;
    text-align: center;
`;

export const CircleImg = styled.img`
    text-align: center;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    opacity: 0.6;

    @media screen and (max-width: 768px) {
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 425px) {
        width: 120px;
        height: 120px;
    }
`;

export const Header1 = styled.h1`
    text-align: center;
    font-size: 1.5rem;
    color: #fff;
    padding: 20px 0px;
`;

export const SectionText = styled.p`
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
    margin: 10vh 15vw;
`;