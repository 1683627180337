import React from 'react';
import { Container, MainRow, Greeting, MiddleSec } from './WelcomeSubsec.styles';


export const WelcomSubsec = ({
  greeting,
}) => {

  return (
    <>
    <Container>
      <MainRow >
        <MiddleSec>
          <Greeting>{greeting}</Greeting>
        </MiddleSec>

      </MainRow>
    </Container>
    </>
  )
}
