import React from 'react'
import BioSection from '../SubSec/BioSection'
import {data4BioSec} from '../SubSec/Data';

const About = () => {
  return (
    <BioSection {...data4BioSec}/>
  )
}

export default About