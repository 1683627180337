import React from 'react'
import { Container, Headline, MainImg, MainText, CenterBioPic } from './BioSection.styles'

const BioSection = ({
    headline,
    bioImg,
    description
}) => {
  return (
      <Container>
          <Headline>{headline}</Headline>
          <CenterBioPic>
            <MainImg src={bioImg} />
          </CenterBioPic>
          <MainText>{description}</MainText>

      </Container>
  )
}

export default BioSection